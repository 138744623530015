<template>
  <div class="preview-header"
       :class="[color]">
    <img v-if="logo"
         :src="logo"
         class="preview-header-logo" />
    <div class="preview-header-brand">
      {{ brand }}
    </div>
    <div class="preview-header-title">
      {{ title }}
    </div>
    <div class="preview-header-pages">
      <ChartTimer v-if="showTimer"
                  :key="currentPage"
                  size="25px"
                  :time="pageTimer"
                  background="#444"
                  color="#fff"
                  @onSuccess="changePage" />
      <span
        v-if="showTimer"
        class="page-count">
        {{ currentPage }} / {{ pageCount }}
      </span>
    </div>
  </div>
</template>

<script>
import ChartTimer from '../ChartTimer';
import config from '../../../config';

export default {
  name: 'PreviewHeader',
  components: {
    ChartTimer,
  },
  props: {
    title: {
      type: String,
      default: 'Betslip',
    },
    bets: {
      type: Array,
      default: () => [],
    },
    multiplePages: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    pageCount: {
      type: Number,
    },
  },
  data() {
    const {
      brand, logo, color, pageTimer,
    } = config;

    return {
      brand,
      logo,
      color,
      pageTimer,
    };
  },
  computed: {
    showTimer() {
      return this.multiplePages && this.currentPage <= this.pageCount;
    },
  },
  methods: {
    changePage() {
      this.$emit('updatePage');
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  padding: 0.5em 0.75em;
  background-color: black;
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.preview-header-title {
  flex: 1;
  text-align: center;
  text-transform: capitalize;
}

.preview-header-logo {
  height: 35px;
  width: 85px;
  float: right;
  margin-right: 0.25em;
  font-size: 28px;
  vertical-align: middle;
}

.preview-header-pages {
  display: flex;
  align-items: center;

  .chart-timer {
    margin-right: 10px;
    margin-top: 5px;
  }
}

.page-count {
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 700;
}
</style>
