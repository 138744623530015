<template>
  <div class="preview-footer">
    <div class="preview-footer-detail"
         :class="{'private-mode': isPrivateMode}">
      <div class="items"
           :class="`items-${detailFieldsToShow.length}`">
        <div class="item"
             :key="field.name"
             v-for="field in detailFieldsToShow">
          <div class="label">
            {{ getLabel(field) }}
          </div>
          <div class="value">
            {{ getValue(field) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPrivateMode"
         class="preview-footer-winning"
         :class="[config.color]">
      <div class="preview-footer-winning-label">{{ maxWinningLabelFormat }}</div>
      <div class="preview-footer-winning-value">{{ maxWinning | money }}</div>
    </div>
  </div>
</template>

<script>
import { filter, isNil, startCase } from 'lodash';
import { money } from '@/filters';
import config from '../../config';

export default {
  name: 'PreviewFooter',
  filters: {
    money,
  },
  props: {
    bonus: {
      type: [Number, String],
      default: 0.00,
    },
    bonusPercentage: {
      type: Number,
      default: 0,
    },
    combinations: {
      type: Number,
      default: 0,
    },
    minWinning: {
      type: [Number, String],
      default: 0.00,
    },
    payment: {
      type: Number,
      default: 0.00,
    },
    maxWinning: {
      type: Number,
      default: 0.00,
    },
    maxWinningLabel: {
      type: String,
    },
    stake: {
      type: Number,
      default: 0.00,
    },
    payinTax: {
      type: Number,
      default: 0.00,
    },
    totalOdds: {
      type: Number,
      default: 0.00,
    },
  },
  data() {
    return {
      config,
      fields: [
        { name: 'combinations' },
        {
          name: 'payment', format: 'money', label: 'currency', hasPrivateOption: true,
        },
        {
          name: 'totalOdds', format: 'money', hasPrivateOption: false,
        },
        {
          name: 'payinTax', format: 'money', label: 'currency', hasPrivateOption: false,
        },
        {
          name: 'stake', format: 'money', label: 'currency', hasPrivateOption: true,
        },
        {
          name: 'bonus', format: 'money', label: 'percentage', hasPrivateOption: false,
        },
        {
          name: 'minWinning', format: 'money', label: 'currency', hasPrivateOption: false,
        },
      ],
    };
  },
  computed: {
    detailFieldsToShow() {
      return filter(this.fields, (field) => {
        const isFieldVisible = !this.isPrivateMode || (this.isPrivateMode && !field.hasPrivateOption);
        const hasValue = !isNil(this[field.name]) && this[field.name] >= 0;

        return isFieldVisible && hasValue && !field.hide;
      });
    },
    isPrivateMode() {
      return window.location.pathname === '/private';
    },
    maxWinningLabelFormat() {
      const currencyLabel = this.config.currency ? `(${this.config.currency})` : '';
      return `${this.maxWinningLabel} ${currencyLabel}`;
    },
  },
  methods: {
    getLabel(field) {
      const baseLabel = this.config[`${field.name}Label`];
      return field.label ? `${baseLabel} ${this.getLabelSuffix(field)}` : baseLabel;
    },
    getLabelSuffix(field) {
      switch (field.label) {
        case 'currency':
          return this.config.currency ? `(${this.config.currency})` : '';
        case 'percentage': {
          const suffix = this[`${field.name}${startCase(field.label)}`];
          return `(${suffix}%)`;
        }
        default:
          return '';
      }
    },
    getValue(field) {
      const baseValue = this[field.name];

      if (field.format) return money(baseValue);

      return baseValue;
    },
  },
};
</script>

<style lang="scss">
.ticket-preview {
  &.sidebar .preview-footer {
    left: auto;
    top: 0;
    height: auto;
    width: 25%;

    .preview-footer-detail {
      width: 100%;
    }

    .preview-footer-winning {
      position: absolute;
      bottom: 0;
      height: 120px;
      width: 100%;
    }

    .preview-footer-detail .item {
      width: 100% !important;
    }
  }

  .preview-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 110px;
    color: #fdfdfd;
    background-color: #424242;
    z-index: 2;
  }

  .preview-footer-detail {
    float: left;
    width: 75%;

    &.private-mode {
      width: 100%;
    }
  }

  .preview-footer-detail {
    .items-1 .item,
    .items-2 .item {
      width: 100%;
    }
  }

  .preview-footer-detail .items-3 .item {
    width: 50%;
    &:nth-child(3) {
      width: 100%;
    }
  }

  .preview-footer-detail .items-4 .item {
    width: 50%;
  }

  .preview-footer-detail .items-5 .item:nth-child(n + 4) {
    width: 50%;
  }

  .preview-footer-detail .items-7 .item:nth-child(-n + 4) {
    width: 25%;
  }

  .preview-footer-detail .item {
    float: left;
    width: 33.3%;
    height: 55px;
    padding: 0.5em;
    border: 1px solid #242424;
    background-color: #424242;
  }

  .preview-footer-detail .item .label {
    opacity: 0.7;
  }

  .preview-footer-detail .item .value {
    font-size: 1.125em;
    font-weight: 700;
  }

  .preview-footer-winning {
    position: relative;
    float: right;
    height: 100%;
    width: 25%;
    padding: 0.5em;
    background-color: #415fb2;
    border: 1px solid #242424;
    border-left: 0;
  }

  .preview-footer-winning-label {
    opacity: 0.7;
    font-weight: 300;
  }

  .preview-footer-winning-value {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    font-size: 1.75em;
    font-weight: 700;
    transform: translate(-50%, -50%);
  }
}
</style>
