<template>
  <div class="reoffer"
       :class="layout">
    <div class="reoffer-modal">
      <div class="reoffer-modal-header">
        <span>Ticket re-offer ({{ ticketReoffer.originalTicket.id }})</span>
      </div>
      <div class="reoffer-modal-body">
        <div class="body-item"
             v-for="(bet, index) in ticketReoffer.originalTicket.ticketBets"
             :key="index">
          <div class="font-bold">{{ bet.event.name }}</div>
          <div>{{ time(bet.event.startsAt) }}</div>
          <div class="body-item-market">
            <div>
              <span>{{ bet.market.name }}</span>
              <span v-if="bet.eventMarketCompetitors.length">
                - {{ competitorsName(bet.eventMarketCompetitors) }}
              </span>
            </div>
            <div>
              <span>Pick: </span>
              <span class="font-bold">{{ bet.marketOutcome.betslipName }}</span>
              <span class="font-bold body-item-odd">{{ bet.odd.toFixed(2) }}</span>
            </div>
          </div>
          <div class="body-item-market text-highlighted"
               v-if="!isMarketSame(index)">
            <div>
              <span>{{ ticketReoffer.reOfferedTicket.ticketBets[index].market.name }}</span>
              <span v-if="ticketReoffer.reOfferedTicket.ticketBets[index].eventMarketCompetitors.length">
                - {{ competitorsName(ticketReoffer.reOfferedTicket.ticketBets[index].eventMarketCompetitors) }}
              </span>
            </div>
            <div>
              <span>Pick: </span>
              <span class="font-bold">{{ ticketReoffer.reOfferedTicket.ticketBets[index].marketOutcome.betslipName }}</span>
              <span class="font-bold body-item-odd">{{ ticketReoffer.reOfferedTicket.ticketBets[index].odd.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="reoffer-modal-footer">
        <div class="footer-button">
          <span class="text-left">
            <span>{{ ticketReoffer.originalTicket.oddMax.toFixed(2) }}</span> |
            <span class="text-highlighted">{{ ticketReoffer.reOfferedTicket.oddMax.toFixed(2) }}</span>
          </span>
          <span class="text-right">{{ totalOddsLabel }}</span>
        </div>
        <div class="footer-button">
          <span class="text-left">
            <span>{{ ticketReoffer.originalTicket.paymentAmount.toFixed(2) }}</span> |
            <span class="text-highlighted">{{ ticketReoffer.reOfferedTicket.paymentAmount.toFixed(2) }}</span>
          </span>
          <span class="text-right">{{ paymentLabel }}</span>
        </div>
        <div class="footer-button">
          <span class="text-left">
            <span>{{ ticketReoffer.originalTicket.winningAmountMax.toFixed(2) }}</span> |
            <span class="text-highlighted">{{ ticketReoffer.reOfferedTicket.winningAmountMax.toFixed(2) }}</span>
          </span>
          <span class="text-right">{{ possibleWinningLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  format,
  toDate,
} from 'date-fns';

export default {
  name: 'PreviewReoffer',
  props: {
    layout: {
      type: String,
    },
    ticketReoffer: {
      type: Object,
    },
    totalOddsLabel: {
      type: String,
      default: 'Total Odds',
    },
    paymentLabel: {
      type: String,
      default: 'Payment',
    },
    possibleWinningLabel: {
      type: String,
      default: 'Possible winning',
    },
  },
  methods: {
    time(time) {
      return format(toDate(time), 'PPPP p');
    },
    competitorsName(competitors) {
      let name = '';
      competitors.forEach((competitor) => {
        name += competitor.player ? competitor.player.displayName : '';
        name += competitor.team ? ` (${competitor.team.name})` : '';
      });
      return name;
    },
    isMarketSame(index) {
      const originals = this.ticketReoffer.originalTicket.ticketBets[index];
      const reoffers = this.ticketReoffer.reOfferedTicket.ticketBets[index];
      return originals.market.id === reoffers.market.id
        && originals.marketOutcome.id === reoffers.marketOutcome.id
        && originals.odd === reoffers.odd;
    },
  },
};
</script>

<style lang="scss">
.reoffer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.7);
  z-index: 100;

  &.horizontal {
    height: 95%;
    width: 100%;
  }

  &.sidebar {
    height: 100%;
    width: 75%;
  }

  .font-bold {
    font-weight: 500;
  }

  .text-highlighted {
    color: #5ead64;
  }

  .reoffer-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 70vw;
    height: 80vh;
    border-radius: 6px;
    color: #455a64;
    background-color: #fff;
  }

  .reoffer-modal-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    border-bottom: 1px solid #dee3e5;
  }

  .reoffer-modal-body {
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    align-content: space-between;
    padding: 70px 20px 80px 20px;
    font-size: 0.875rem;
  }

  .body-item {
    display: flex;
    flex-wrap: wrap;
    flex: 25%;
    margin-right: 50px;
    padding: 15px 0;
    height: 110px;
    line-height: 20px;
    border-bottom: 1px solid #dee3e5;

    &:nth-child(3n) {
      margin-right: 0;
    }

    & > div {
      flex: 100%;
    }

    .body-item-odd {
      padding-left: 50px;
    }

    .body-item-market {
      flex: 50%;
    }
  }

  .reoffer-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    padding: 15px 10px;
    border-top: 1px solid #dee3e5;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .footer-button {
    display: flex;
    flex: 30%;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
    font-weight: 500;
    border: 1px solid #bfc5cd;
    border-radius: 2px;

    .text-left > span {
      padding: 0 5px;
    }

    .text-right {
      margin-left: auto;
    }
  }
}
</style>
