import { render, staticRenderFns } from "./PreviewMarketing.vue?vue&type=template&id=29dde010&scoped=true"
import script from "./PreviewMarketing.vue?vue&type=script&lang=js"
export * from "./PreviewMarketing.vue?vue&type=script&lang=js"
import style0 from "./PreviewMarketing.vue?vue&type=style&index=0&id=29dde010&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29dde010",
  null
  
)

export default component.exports