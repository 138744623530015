<template>
  <div class="wrapper">
    <slot
      :bet="bet"
      :betName="betName"
      :color="color"
      :isBanker="isBanker"
      :isLocked="isLocked"
      :isSystem="isSystem"
      :matchTime="matchTime"
      :matchTimeShort="matchTimeShort"
      :matchDayAndTimeShort="matchDayAndTimeShort"
      :oddChange="oddChange"
      :oddChangeColor="oddChangeColor"
      :oddChangeIcon="oddChangeIcon">
    </slot>
  </div>
</template>

<script>
import { isBoolean, toNumber } from 'lodash';
import {
  format,
  toDate,
} from 'date-fns';
import { odd } from '@/filters';

export default {
  name: 'PreviewBetSport',
  filters: {
    odd,
  },
  props: {
    bet: {
      type: Object,
    },
    color: {
      type: String,
    },
    isSystem: {
      type: Boolean,
    },
  },
  computed: {
    isBanker() {
      return isBoolean(this.bet.banker) ? this.bet.banker : !!toNumber(this.bet.banker);
    },
    isLocked() {
      let isBetActive = this.bet.active && this.bet.betExists;
      isBetActive = Object.prototype.hasOwnProperty.call(this.bet, 'betActive')
        ? (isBetActive && this.bet.betActive) : isBetActive;
      return !this.bet.bettingStatus || !isBetActive;
    },
    betName() {
      let name = this.bet.betName;
      name = this.bet.competitorDisplay ? `${name} - ${this.bet.competitorDisplay}` : name;
      return name;
    },
    matchTime() {
      return this.formatTime('PPPP p');
    },
    matchTimeShort() {
      return this.formatTime('dd-MM-yyyy HH:mm');
    },
    matchDayAndTimeShort() {
      return this.formatTime('EEE, dd-MM-yyyy HH:mm');
    },
    oddChange() {
      const currentOdd = toNumber(this.bet.mboOddValue);
      const previousOdd = toNumber(this.bet.previousOddValue);
      const isChange = previousOdd > 0 && currentOdd !== previousOdd;

      return {
        currentOdd,
        previousOdd,
        isChange,
      };
    },
    oddChangeColor() {
      const change = this.oddChange;

      if (change.isChange) {
        return change.currentOdd > change.previousOdd ? 'up' : 'down';
      }

      return null;
    },
    oddChangeIcon() {
      const change = this.oddChange;

      if (change.isChange) {
        return change.currentOdd > change.previousOdd ? 'arrow_drop_up' : 'arrow_drop_down';
      }

      return null;
    },
  },
  methods: {
    formatTime(dateFormat) {
      const time = this.bet.matchDateTimeUTC || this.bet.matchDateTime;
      return time ? format(toDate(time), dateFormat) : null;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    height: 100%;
  }
</style>
