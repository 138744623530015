<template>
  <div class="preview-bets-header">
    <div class="preview-bets-header-id">
      {{ id }}
    </div>
    <div class="preview-bets-header-time">
      {{ time }}
    </div>
    <div class="preview-bets-header-match">
      {{ match }}
    </div>
    <div class="preview-bets-header-market">
      {{ market }}
    </div>
    <div class="preview-bets-header-outcome">
      {{ outcome }}
    </div>
    <div class="preview-bets-header-odd">
      {{ odd }}
    </div>
    <div class="preview-bets-header-result">
      {{ result }}
    </div>
    <div class="preview-bets-header-status">
      {{ status }}
    </div>
  </div>
</template>

<script>
import config from '../../../config';

export default {
  name: 'PreviewBetsHeader',
  data() {
    return {
      status: config.status || 'status',
      result: config.result || 'result',
      odd: config.odd || 'odd',
      outcome: config.outcome || 'outcome',
      market: config.market || 'market',
      match: config.match || 'match',
      time: config.time || 'time',
      id: config.id || 'id',
    };
  },
};
</script>

<style lang="scss" scoped>
.preview-bets-header {
  display: flex;
  align-items: center;
  padding: 1px;
  color: #fff;
  height: 30px;
  text-transform: uppercase;

  & > div {
    padding: 0.2em;
  }

  &-id {
    flex-basis: 6%;
  }

  &-time {
    flex-basis: 11%;
  }

  &-match {
    flex-basis: 30%;
  }

  &-market {
    flex-basis: 23%;
  }

  &-outcome {
    flex-basis: 11%;
  }

  &-odd {
    flex-basis: 6%;
  }

  &-result {
    flex-basis: 8%;
  }

  &-status {
    flex-basis: 5%;
  }
}
</style>
