<template>
  <div class="chart-timer">
    <svg
      :width="size"
      :height="size"
      viewBox="-1 -1 2 2"
      class="donut">
      <circle
        cx="0"
        cy="0"
        r="1"
        :fill="background" />

      <path
        :d="calc"
        :fill="color"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ChartTimer',
  props: {
    background: {
      type: String,
      default: '#777',
    },
    color: {
      type: String,
      default: '#00f',
    },
    size: {
      type: String,
      default: '30px',
    },
    time: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      percent: 1,
      startX: 1,
      startY: 0,
      currentPercent: 0,
      intervalId: null,
    };
  },
  computed: {
    calc() {
      const percentValue = (this.currentPercent / 100);
      const [endX, endY] = this.getPieVal(percentValue);
      const largeArcFlag = percentValue > 0.5 ? 1 : 0;
      const pathData = [
        `M ${this.startX} ${this.startY}`,
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        'L 0 0',
      ].join(' ');

      return pathData;
    },
  },
  methods: {
    getPieVal(per) {
      const x = Math.cos(2 * Math.PI * per);
      const y = Math.sin(2 * Math.PI * per);
      return [x, y];
    },
    start() {
      this.intervalId = setInterval(() => {
        this.currentPercent += 1;

        if (this.currentPercent > 100) {
          this.currentPercent = 0;
          clearInterval(this.intervalId);
          this.$emit('onSuccess');
        }
      }, (this.time * 10));
    },
  },
  mounted() {
    this.start();
  },
  destroyed() {
    if (this.intervalId) clearInterval(this.intervalId);

    this.currentPercent = 0;
  },
};
</script>

<style scoped>
.donut{
  transform: rotate(-90deg);
}
</style>
