<template>
  <div class="wrapper">
    <div class="bet bet-sport">
      <div class="bet-content">
        <div class="bet-match-display-id">{{ matchDisplayId }}</div>
        <div v-if="time"
             class="bet-time">{{ matchTimeShort }}
        </div>
        <div class="bet-match-name">
          <span class="bet-ways"
                :class="[color]"
                v-if="ways">
            W
          </span>
          <span class="bet-banker"
                :class="[color]"
                v-if="isSystem && betBanker">
            F
          </span>
          <span class="bet-match-name-text">
            {{ matchName }}
          </span>
        </div>
        <div class="bet-name">
          <span class="bet-name-text">{{ marketName }}</span>
        </div>
        <div class="bet-outcome-name">{{ marketOutcomeName }}</div>

        <div class="bet-outcome-odd">
          <span :class="{'bet-outcome-odd-void': status === 'VOID'}">{{ odd | odd }}</span>
          <span v-if="status === 'VOID'">1.00</span>
        </div>

        <div class="bet-result">
          {{ result }}
        </div>
        <div class="bet-status"
             :class="`status-color-${statusColor}`">
          <i class="material-icons">
            {{ statusIcon }}
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { odd } from '@/filters';
import {
  format,
  toDate,
} from 'date-fns';

export default {
  name: 'PreviewBetChecked',
  filters: {
    odd,
  },
  props: {
    bet: Object,
    color: String,
    isSystem: Boolean,
    product: String,
    unitBets: Array,
  },
  data() {
    return {
      statusIcons: {
        CLOSED: 'cancel',
        LOST: 'cancel',
        CANCELLED: 'cancel',
        REJECTED: 'cancel',
        WON: 'check_circle',
        PAYEDOUT: 'check_circle',
        OPEN: 'schedule',
        HALFWON: 'download_done',
        HALFLOST: 'download_done',
        VOID: 'do_disturb_on',
        IN_PLAY: '',
      },
      statusColors: {
        CLOSED: 'red',
        LOST: 'red',
        CANCELLED: 'red',
        REJECTED: 'red',
        WON: 'green',
        HALFWON: 'orange',
        HALFLOST: 'red',
        PAYEDOUT: 'green',
        OPEN: 'default',
        VOID: 'gray',
        IN_PLAY: 'default',
      },
    };
  },
  computed: {
    isSportsbookSM() {
      return this.product === 'SportsbookSM';
    },
    matchDisplayId() {
      return this.isSportsbookSM ? this.bet.event.displayId : this.bet.matchDisplayId;
    },
    matchTimeShort() {
      return this.formatTime('dd.MM | HH:mm');
    },
    matchName() {
      let prefix;
      let teams;

      if (this.isSportsbookSM) {
        prefix = this.bet.tournament?.prefix;
        teams = this.setSportsbookSMTeams();
      } else {
        prefix = this.bet.tournamentPrefix;
        teams = this.setTeams();
      }

      const tournamentPrefix = prefix ? `${prefix}/` : '';
      return `${tournamentPrefix}${teams.team1} - ${tournamentPrefix}${teams.team2}`;
    },
    marketName() {
      if (this.isSportsbookSM) return this.formatSportsbookSMMarketName(this.bet);

      return this.formatBetName(this.bet);
    },
    marketOutcomeName() {
      if (this.isSportsbookSM) return this.bet.marketOutcome.name;

      return this.bet.betOutcome.betOutcomeShortName || this.bet.betOutcome.betOutcomeDisplayName;
    },
    betBanker() {
      return this.isSportsbookSM ? this.bet.banker : parseInt(this.bet.ticketBetBank, 10);
    },
    ways() {
      const unitBet = this.unitBets.find((bet) => bet.betId === this.bet.id);
      return unitBet?.ways;
    },
    odd() {
      return this.isSportsbookSM ? this.bet.odd : this.bet.oddValue;
    },
    status() {
      const betStatus = this.isSportsbookSM ? this.bet.status.value : this.bet.statusInfo.value;
      return betStatus.toUpperCase();
    },
    statusColor() {
      return this.statusColors[this.status];
    },
    statusIcon() {
      return this.statusIcons[this.status];
    },
    time() {
      return this.isSportsbookSM ? this.bet.startsAt : this.bet.matchDateTimeUTC || this.bet.matchDateTime;
    },
    result() {
      if (this.isSportsbookSM) {
        return this.bet.eventResults?.find((result) => result.resultType?.constName === 'FT')?.value || '-';
      }

      return this.bet.results?.find((result) => result.providerResultId === 'FT')?.matchResultValue || '-';
    },
  },
  methods: {
    formatTime(dateFormat) {
      return this.time ? format(toDate(this.time), dateFormat) : null;
    },
    formatSportsbookSMMarketName(bet) {
      const specialValues = this.bet.specialValues?.length ? `- (${this.bet.specialValues.join(' / ')})` : '';

      if (!bet.market.isCompetitor) return `${bet.market.name} ${specialValues}`;

      const marketCompetitors = bet.eventMarketCompetitors.map((competitor) => (
        `${competitor.player.name} (${competitor.team.name})`
      ));

      return `${bet.market.name} - ${marketCompetitors.join(' - ')}`;
    },
    formatBetName(bet) {
      const patterns = ['[X]', '[Y]', '[Z]', '[Q]'];
      const parts = bet.specialValue.toString().split('|');
      let replaced = false;
      let formattedName = bet.bet.betDisplayName || bet.bet.betShortName;

      parts.forEach((part, i) => {
        const pattern = patterns[i];

        if (!formattedName.includes(pattern)) return;

        formattedName = formattedName.replace(pattern, part);
        replaced = true;
      });

      if (!replaced) {
        const sv = !bet.bet.betGroupingEnabled && bet.specialValue !== '*' ? `(${bet.specialValue})` : '';
        formattedName = `${formattedName} ${sv}`;
      }

      return formattedName;
    },
    setTeams() {
      const [teamX, teamY] = this.bet.teams;
      const sortedByType = teamX?.teamType === '1';

      return {
        team1: sortedByType ? teamX.teamDisplayName : teamY.teamDisplayName,
        team2: sortedByType ? teamY.teamDisplayName : teamX.teamDisplayName,
      };
    },
    setSportsbookSMTeams() {
      const [teamX, teamY] = this.bet.eventCompetitors;
      const sortedByType = teamX?.type === 1;

      return {
        team1: sortedByType ? teamX.teamName : teamY.teamName,
        team2: sortedByType ? teamY.teamName : teamX.teamName,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet {
    color: #161616;
    background-color: #f5f5f5;
    height: 30px;
    border-radius: 3px;
  }

  .bet-sport {
    position: relative;
    width: 100%;
    display: flex;

    .bet-display-id {
      font-weight: 700;
      padding-right: 0.5em;
    }

    .bet-content {
      line-height: 1.2;
      width: 100%;
      display: flex;

      & > div {
        flex-basis: 20%;
        padding: .2em;
        display: flex;
        align-items: center;

      }

      .bet-match-display-id {
        flex-basis: 6%;
        font-weight: 700;
      }

      .bet-time {
        flex-basis: 11%;
      }

      .bet-result {
        flex-basis: 8%;
        font-weight: 700;
      }

      .bet-outcome-odd {
        font-weight: 700;
        flex-basis: 6%;

        &-void {
          text-decoration: line-through;
          margin-right: 2px;
        }
      }

      .bet-outcome-name {
        font-weight: 700;
        flex-basis: 11%;
      }

      .bet-status {
        flex-basis: 5%;
        justify-content: center;
        border-radius: 3px;
      }

      .bet-match-name {
        flex-basis: 30%;
        font-weight: 700;
        overflow: hidden;
      }

      .bet-name {
        flex-basis: 23%;
        overflow: hidden;
      }

      .bet-match-name-text,
      .bet-name-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .bet-banker,
      .bet-ways {
        background-color: #000;
        color: #fff;
        height: 100%;
        min-width: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 10px;
        border-radius: 3px;
      }

      .bet-outcome-icon {
        color: #ff0000;
        line-height: 0;
      }

      .bet-outcome-icon.up {
        color: #2c9a5b;
      }
    }

    .status-color {
      &-default {
        background: transparent;
      }

      &-red {
        background: #e42c0d;
        color: white;
      }

      &-green {
        background: #2c9a5b;
        color: white;
      }

      &-gray {
        background: #808080;
        color: white;
      }

      &-orange {
        background: #DFBF52;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 600px) and (max-height: 800px) {
  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 800px) and (max-height: 600px) {
  .bet-content {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1280px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1280px) and (min-height: 1024px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1600px) and (min-height: 900px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 900px) and (min-height: 1600px) {
  .bet-content {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .bet-content {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1080px) and (min-height: 1920px) {
  .bet-content {
    font-size: 18px !important;
  }
}
</style>
