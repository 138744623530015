import TicketPreviewOutcomes from './TicketPreviewOutcomes';
import TicketPreviewReoffer from './TicketPreviewReoffer';

export default {
  name: 'SportTicketPreviewPopup',
  components: {
    TicketPreviewOutcomes,
    TicketPreviewReoffer,
  },
  props: {
    brand: {
      type: String,
      default: 'brand',
    },
    layout: {
      type: String,
    },
    paymentLabel: {
      type: String,
    },
    popupData: {
      type: Object,
      default: () => {},
    },
    possibleWinningLabel: {
      type: String,
    },
    title: {
      type: String,
      default: 'title',
    },
    totalOddsLabel: {
      type: String,
    },
  },
  render(createElement) {
    const hasOutcomes = this.popupData.matchBetOutcomes;
    const hasReoffer = this.popupData.ticketReoffer;

    if (hasOutcomes) {
      return createElement(TicketPreviewOutcomes, {
        props: {
          brand: this.brand,
          layout: this.layout,
          matchBetOutcomes: this.popupData.matchBetOutcomes,
          title: this.title,
        },
      });
    }

    if (hasReoffer) {
      return createElement(TicketPreviewReoffer, {
        props: {
          brand: this.brand,
          layout: this.layout,
          paymentLabel: this.paymentLabel,
          possibleWinningLabel: this.possibleWinningLabel,
          ticketReoffer: this.popupData.ticketReoffer,
          title: this.title,
          totalOddsLabel: this.totalOddsLabel,
        },
      });
    }

    return createElement('div');
  },
};
