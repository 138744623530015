<template>
  <div class="popup full-width">
    <div class="popup-header">
      <div class="title left">
        {{title}}
      </div>
      <div class="brand right">
        {{brand}}
      </div>
    </div>
    <div class="popup-content left">
      <div class="match-info-wrap left full-width">
        <div class="sport-country-tournament">
          {{matchMeta}}
        </div>

        <div class="match-info left full-width">
          <div class="match-display-id left">
            {{matchBetOutcomes.displayId}}
          </div>
          <div class="match-name left">
            {{matchBetOutcomes.matchName}}
          </div>
          <div class="match-date-time right">
            {{matchDateTime}}
          </div>
        </div>
      </div>
      <!-- Bets -->
      <div v-if="matchBetOutcomes.bets">
        <div v-for="(bet, key) in bets"
             v-bind:key="key"
             :class="bet.betActive ? '' : 'disabled'"
             class="bet">
          <div class="bet-name">
            {{bet.fullBetName}}
          </div>
          <div v-for="(outcome, key) in bet.mbOutcomes"
               v-bind:key="key"
               :class="setOutcomeClass(outcome, bet.mbOutcomes.length)">
            <div class="odd-id">
              {{outcome.betOutcomeName}}
            </div>
            <div class="odd-value">
              {{outcome.displayOddValue}}
            </div>
          </div>
        </div>
      </div>
      <!-- Outcomes -->
      <div v-if="matchBetOutcomes.outcomes"
           class="outcomes-wrap left full-width">
        <div class="bet-name">
          {{matchBetOutcomes.betName}}
        </div>
        <div v-for="(outcome, key) in outcomes"
             v-bind:key="key"
             :class="setOutcomeClass(outcome, outcomes.length)">
          <div class="odd-id">
            {{outcome.providerBetOutcomeId || outcome.betOutcomeName}}
          </div>
          <div class="odd-value">
            {{outcome.displayOddValue || outcome.matchBetOutcomeOddValue}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  format,
  toDate,
} from 'date-fns';
import { orderBy } from 'lodash';

export default {
  name: 'PreviewOutcomes',
  props: {
    matchBetOutcomes: {
      type: Object,
    },
    title: {
      type: String,
    },
    brand: {
      type: String,
    },
  },
  computed: {
    matchDateTime() {
      const dt = this.matchBetOutcomes.matchDateTimeUTC
        || this.matchBetOutcomes.matchDateTime;
      return dt ? format(toDate(dt), 'PPPP p') : null;
    },
    matchMeta() {
      let content = [
        this.matchBetOutcomes.sport,
        this.matchBetOutcomes.category,
        this.matchBetOutcomes.tournament,
      ];

      content = content.filter(Boolean);

      if (content.length) {
        return content.join(' | ');
      }
      return '';
    },
    outcomes() {
      return orderBy(this.matchBetOutcomes.outcomes, 'matchBetOutcomePosition', 'asc');
    },

    bets() {
      return orderBy(this.matchBetOutcomes.bets, 'betPosition', 'asc');
    },
  },
  methods: {
    columnClassCalc(len) {
      const col3 = [3, 6, 9, 12];
      const col3Treshold = Math.max(...col3) + 1;

      if (col3.indexOf(len) !== -1 || len >= col3Treshold) {
        return 'outcome-3';
      }

      return 'outcome-2';
    },

    setOutcomeClass(outcome, outcomesLen) {
      const classArr = [];
      const isOutcomeDisabled = outcome.matchBetOutcomeBlocked
        || (Object.prototype.hasOwnProperty.call(outcome, 'betOutcomeActive') && !outcome.betOutcomeActive);

      classArr.push(this.columnClassCalc(outcomesLen));

      if (isOutcomeDisabled) {
        classArr.push('disabled');
      }

      return classArr.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.4;
}

.selected {
  background: #415fb2 !important;

  div {
    color: #ffffff !important;
  }
}

.popup-wrap {
  height: 860px;

  .popup {
    height: 828px;
    margin: 0 auto;
    background: #c1c0c0;
  }

  .popup-content {
    background: #c1c0c0;
  }

  .popup-header {
    position: relative;
    height: 50px;
    background: #1a2647;
    font-size: 1.5rem;
    color: #5c5d61;
    padding: 0.7rem 1rem;
  }

  .match-info-wrap {
    background: #f5f5f5;
  }

  .sport-country-tournament {
    background: #f5f5f5;
    color: #404040;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    margin: 25px 0 5px 0;
  }

  .match-display-id {
    width: 15%;
    color: #415fb2;
    font-size: 1.15rem;
    font-weight: bold;
  }

  .match-info {
    background: #f5f5f5;
    margin-bottom: 8px;
    padding: 0 14px;
  }

  .bet {
    width: 50%;
    display: inline-block;
    margin-top: 6px;
    box-sizing: border-box;

    &:nth-child(2n) {
      border-left: 6px solid #c1c0c0;
    }
  }

  .outcomes-wrap {
    margin-top: 6px;
  }

  .bet-name {
    padding: 6px 0 6px 14px;
    background: #f5f5f5;
    font-weight: bold;
  }

  .match-name {
    width: 70%;
    color: #141414;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .match-date-time {
    width: 15%;
    color: #333333;
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
  }

  [class|='outcome'] {
    padding: 16px;
    float: left;
    box-sizing: border-box;
    border-width: 3px 0 0 3px;
    border-style: solid;
    border-color: #c1c0c0;
    background: #dcdcdc;
  }

  .outcome-2 {
    width: 50%;

    &:nth-child(2n) {
      border-left: 0;
    }
  }

  .outcome-3 {
    width: 33.33%;

    &:nth-child(3n + 2) {
      border-left: 0;
    }
  }

  .outcome-4 {
    width: 25%;

    &:nth-child(4n + 2) {
      border-left: 0;
    }
  }

  &:first-child {
    border-left: 0;
  }

  .odd-id,
  .odd-value {
    width: 50%;
    display: inline-block;
    color: #161616;
  }

  .odd-value {
    text-align: right;
    font-weight: bold;
  }
}
</style>
