<template>
  <div class="bet bet-sport">
    <div class="bet-overlay"
         v-if="isLocked">
      <div class="bet-overlay-box"
           :class="[color]">
      </div>
      <i class="bet-overlay-icon material-icons">
        lock
      </i>
    </div>
    <div class="bet-heading clearfix">
      <div class="bet-ordinal-number"
           v-if="bet.ordinalNumber">
        {{bet.ordinalNumber}}
      </div>
      <div class="bet-display-id">{{bet.matchDisplayId}}</div>
      <div class="bet-time"
           v-if="matchTime">
        {{matchDayAndTimeShort}}
      </div>
      <div class="bet-flags">
        <div class="bet-banker"
             :class="[color]"
             v-if="isSystem && isBanker">
          F
        </div>
      </div>
    </div>
    <div class="bet-content">
      <div class="bet-match-name">{{bet.matchName}}</div>
      <div class="bet-name">{{betName}}</div>
      <div class="bet-outcome clearfix">
        <div class="bet-outcome-name">{{bet.betOutcomeName}}</div>
        <div class="bet-outcome-odd">{{bet.mboOddValue | odd}}</div>
        <i class="bet-outcome-icon material-icons"
           :class="[oddChangeColor]"
           v-if="oddChangeIcon">
          {{oddChangeIcon}}
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { odd } from '@/filters';

export default {
  name: 'PreviewBetSportCards',
  filters: {
    odd,
  },
  props: {
    bet: Object,
    betName: String,
    betsLayout: String,
    color: String,
    isBanker: Boolean,
    isLocked: Boolean,
    isSystem: Boolean,
    matchTime: String,
    matchDayAndTimeShort: String,
    oddChange: Object,
    oddChangeColor: String,
    oddChangeIcon: String,
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet-sport {
    position: relative;

    .bet-heading {
      position: relative;
      line-height: 1.2;
      height: 22px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .bet-ordinal-number {
      width: 32px;
      text-align: center;
      float: left;
      background-color: #000;
      color: #fff;
      margin-right: 8px;
      border-radius: 3px;
      font-size: 18px;
    }

    .bet-display-id {
      float: left;
      font-weight: 700;
      padding: 0.15em 0.5em 0 0;
    }

    .bet-time {
      float: left;
      opacity: 0.4;
      padding: 0.2em 0;
    }

    .bet-content {
      padding: 0.2em;
      line-height: 1.2;
    }

    .bet-outcome-name {
      font-weight: 700;
      float: left;
    }

    .bet-outcome-odd {
      font-weight: 700;
      float: right;
    }

    .bet-outcome-icon {
      float: right;
      color: #ff0000;
    }

    .bet-outcome-icon.up {
      float: right;
      color: #2c9a5b;
    }

    .bet-flags {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-weight: 700;
      font-size: 1.125em;
      line-height: 35px;
      overflow: hidden;
      padding: 0.2em 0;
    }

    .bet-banker {
      float: right;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 0 1em;
      line-height: 1.4;
      border-top-right-radius: 3px;
    }

    .bet-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }

    .bet-overlay-box {
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.6;
    }

    .bet-overlay-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2em;
    }
  }
}
</style>
