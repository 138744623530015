import { isNumber, toNumber } from 'lodash';

// Return number rounded on two decimals
export function money(value) {
  const number = isNumber(value) ? value : toNumber(value);
  const k = 10 ** 2; // Math.pow
  return (Math.round(number * k) / k).toFixed(2);
}

export function odd(value) {
  const number = isNumber(value) ? value : toNumber(value);
  return number.toFixed(2);
}
