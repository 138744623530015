<template>
  <div class="bet bet-sport">
    <div class="bet-overlay"
         v-if="isLocked">
      <div class="bet-overlay-box"
           :class="[color]">
      </div>
      <i class="bet-overlay-icon material-icons">
        lock
      </i>
    </div>
    <div class="bet-content">
      <div class="bet-ordinal-number"
        v-if="bet.ordinalNumber">
        {{bet.ordinalNumber}}
      </div>
      <div class="bet-match-display-id">{{ bet.matchDisplayId }}</div>
      <div class="bet-match-name">{{bet.matchName}}</div>
      <div class="bet-name">{{betName}}</div>
      <div class="bet-outcome-name">{{bet.betOutcomeName}}</div>
        <div class="bet-outcome-odd">
          {{bet.mboOddValue | odd}}
          <i class="bet-outcome-icon material-icons"
            :class="[oddChangeColor]"
            v-if="oddChangeIcon">
            {{oddChangeIcon}}
          </i>
        </div>
      <div
        v-if="matchTimeShort"
        class="bet-time">{{ matchTimeShort }}
      </div>
      <div class="bet-banker"
          :class="[color]"
          v-if="isSystem && isBanker">
        F
      </div>
    </div>
  </div>
</template>

<script>
import { odd } from '@/filters';

export default {
  name: 'PreviewBetSportList',
  filters: {
    odd,
  },
  props: {
    bet: Object,
    betName: String,
    betsLayout: String,
    color: String,
    isBanker: Boolean,
    isLocked: Boolean,
    isSystem: Boolean,
    matchTimeShort: String,
    oddChange: Object,
    oddChangeColor: String,
    oddChangeIcon: String,
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet-sport {
    position: relative;
    width: 100%;
    display: flex;

    .bet-display-id {
      font-weight: 700;
      padding-right: 0.5em;
    }

    .bet-content {
      padding: 0.2em;
      line-height: 1.2;
      width: 100%;
      display: flex;

      & > div {
        flex-basis: 23%;
        padding: .2em;
        display: flex;
        align-items: center;

      }

      .bet-ordinal-number {
        flex-basis: 2%;
        justify-content: center;
        background-color: #000;
        color: #fff;
        border-radius: 3px;
        margin-right: 0.45em;
      }

      .bet-match-display-id {
        flex-basis: 5%;
      }

      .bet-outcome-odd {
        font-weight: 700;
        flex-basis: 10%;
      }

      .bet-outcome-name {
        font-weight: 700;
        flex-basis: 10%;
      }

      .bet-match-name {
        flex-basis: 28%;
      }

      .bet-banker {
        background-color: black;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 35px;
        display: flex;
        justify-content: space-around;
      }

      .bet-outcome-icon {
        color: #ff0000;
        line-height: 0;
      }

      .bet-outcome-icon.up {
        color: #2c9a5b;
      }
    }

    .bet-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }

    .bet-overlay-box {
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.6;
    }

    .bet-overlay-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2em;
    }
  }
}
</style>
