<template>
  <div class="popup-wrap full-width full-height"
       v-show="active">
    <component :is="getPopupComponent()"
               :title="title"
               :layout="layout"
               :popupData="popupData"
               :brand="brand"
               :totalOddsLabel="totalOddsLabel"
               :paymentLabel="paymentLabel"
               :possibleWinningLabel="possibleWinningLabel" />
  </div>
</template>

<script>
import { includes } from 'lodash';
import { eventBus } from '@/utility';
import config from '../../config';
import SportTicketPreviewPopup from './sportsbook/TicketPreviewPopup';

const productPopupComponents = {
  sport: 'SportTicketPreviewPopup',
};

export default {
  name: 'PreviewPopup',
  components: {
    SportTicketPreviewPopup,
  },
  props: {
    activeProduct: {
      type: Object,
    },
    type: {
      type: String,
      default: 'sport',
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
      brand: config.brand,
      layout: config.layout,
      paymentLabel: config.paymentLabel,
      popupData: {},
      possibleWinningLabel: config.possibleWinningLabel,
      totalOddsLabel: config.totalOddsLabel,
    };
  },
  watch: {
    activeProduct(newValue, oldValue) {
      const oldName = oldValue && oldValue.name;
      const newName = newValue && newValue.name;
      if (oldName === newName) return;

      // When product is changed, popup should be hidden
      this.active = false;
    },
  },
  methods: {
    checkPopupState({ data }) {
      if (!data) return;
      const ticketAction = (data.action || '').toLowerCase();
      if (!includes(ticketAction, 'popup')) {
        return;
      }

      if (includes(ticketAction, 'popupclose')) {
        this.active = false;
        this.popupData = {};
      } else {
        this.active = true;
        this.popupData = data;
      }

      this.$emit('change', this.active);
    },
    getPopupComponent() {
      return productPopupComponents[this.type];
    },
  },
  mounted() {
    eventBus.$on('TicketChanged', this.checkPopupState);
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  position: absolute;
  top: 0;
  z-index: 1;
}
</style>
