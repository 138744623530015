<template>
  <div class="ticket-preview"
       :class="layout">
    <PreviewHeader :title="ticketTitle" />
    <PreviewBody :ticket="ticket"
                 :renderType="ticketRenderType" />
    <PreviewPopup :title="ticketTitle"
                  :active-product="activeProduct"
                  @change="onPopupChanged" />
    <PreviewMarketing v-if="marketingEnabled"
                      :is-visible="isIdle" />
  </div>
</template>

<script>
import {
  includes,
  isEmpty,
  isNil,
  startCase,
} from 'lodash';
import { eventBus } from '@/utility';
import PreviewBody from '@/components/PreviewBody';
import PreviewHeader from '@/components/PreviewHeader';
import PreviewMarketing from '@/components/PreviewMarketing';
import PreviewPopup from '@/components/PreviewPopup';
import config from '../../config';

export default {
  name: 'TicketPreview',
  components: {
    PreviewBody,
    PreviewHeader,
    PreviewMarketing,
    PreviewPopup,
  },
  data() {
    return {
      activeProduct: null,
      activeProductName: '',
      betType: '',
      config,
      layout: config.layout,
      ticket: {},
      payloadData: {},
      marketingEnabled: config.marketingEnabled,
      isIdle: false,
      hideInfo: false,
      idleTimeout: null,
      availableProducts: {
        PreMatchBetting: { name: 'PreMatchBetting', type: 'sport' },
        LiveBetting: { name: 'LiveBetting', type: 'sport' },
        SportsbookSM: { name: 'SportsbookSM', type: 'sport' },
      },
      popupActive: false,
    };
  },
  computed: {
    activeBets() {
      return this.ticket.bets && this.ticket.bets.length;
    },
    ticketRenderType() {
      if (!this.activeProduct) {
        return null;
      }

      return this.activeProduct.type;
    },
    ticketTitle() {
      if (!this.activeProduct || isEmpty(this.ticket)) {
        return null;
      }

      const name = this.ticket.config?.ticketGroup;

      switch (this.ticketRenderType) {
        case 'sport': {
          const type = startCase(this.ticket.id);
          return `${this.activeProductName || name}: ${this.betType || type}`;
        }
        case 'balls': {
          const bets = this.ticket.bets[0];
          return bets.displayName;
        }
        default:
          return name;
      }
    },
  },
  methods: {
    onPopupChanged(popupActive) {
      this.popupActive = popupActive;
      this.checkIdleState();
    },
    setTicket(data) {
      if (!data) return;
      const product = this.availableProducts[data.productId];
      if (!product) return;

      this.activeProduct = product;
      this.activeProductName = data?.productName || '';
      this.betType = data?.betType || '';

      // Do nothing in case message is about popup state
      const ticketAction = (data.action || '').toLowerCase();
      if (includes(ticketAction, 'popup')) {
        return;
      }

      this.ticket = isNil(data.ticket) ? {} : data.ticket;

      this.checkIdleState();
    },
    rescheduleIdle() {
      // clear previous timeout
      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = null;
      }

      // schedule new timeout
      const idleTimeoutInMs = this.config.idleTimeout * 1000;

      this.idleTimeout = setTimeout(() => {
        this.isIdle = true;
      }, idleTimeoutInMs);
    },
    checkIdleState() {
      if (this.hideInfo) {
        this.isIdle = true;
        return;
      }

      if (this.activeBets || this.popupActive) {
        this.deactivateIdleState();
      }

      this.rescheduleIdle();
    },
    deactivateIdleState() {
      if (!this.hideInfo) {
        this.isIdle = false;
      }

      this.rescheduleIdle();
    },
    onModeChanged(data) {
      this.hideInfo = data.mode === 'hidden';
      this.checkIdleState();
    },
    onUserChanged(data) {
      if (data.action !== 'LoggingOut') return;

      this.ticket = {};
    },
    setListeners() {
      eventBus.$on('TicketChanged', this.setTicket);
      eventBus.$on('ModeChanged', this.onModeChanged);
      eventBus.$on('UserChanged', this.onUserChanged);
    },
  },
  mounted() {
    this.setListeners();
    this.checkIdleState();
  },
};
</script>

<style lang="scss">
.ticket-preview {
  position: relative;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  padding: 0;

  .clearfix:after {
    content: '';
    display: table;
    clear: both;
  }
}
</style>
