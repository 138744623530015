<template>
  <div class="preview-body">
    <div class="preview-placeholder"
         v-if="!ticket.config">
      <img v-if="logo"
           :src="logo"
           class="preview-placeholder-logo" />
      <span class="preview-placeholder-text">
        {{ placeholder }}
      </span>
    </div>
    <div class="preview-content">
      <div class="preview-content-wrap"
           v-if="ticket.config">
        <PreviewBetsHeader />
        <div class="bets">
          <div class="bets-items clearfix">
            <div class="bets-item list"
                 :key="bet.uid"
                 v-for="bet in bets">
              <PreviewBet :bet="bet"
                          :is-system="isSystem"
                          :color="color"
                          :unit-bets="ticketUnitBets"
                          :product="product" />
            </div>
          </div>
        </div>
        <PreviewFooter :bonus="bonus"
                       :bonus-percentage="bonusPercentage"
                       :combinations="combinations"
                       :payment="payment"
                       :stake="stake"
                       :min-winning="minWinning"
                       :max-winning="maxWinning"
                       :max-winning-label="maxWinningLabel"
                       :payin-tax="payinTax" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewBet from './PreviewBet';
import PreviewFooter from '../PreviewFooter';
import PreviewBetsHeader from './PreviewBetsHeader';
import config from '../../../config';

export default {
  name: 'PreviewBody',
  components: {
    PreviewBet,
    PreviewFooter,
    PreviewBetsHeader,
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    bets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const { logo, color, placeholder } = config;

    return { logo, color, placeholder };
  },
  computed: {
    isSportsbookSM() {
      return this.product === 'SportsbookSM';
    },
    product() {
      return this.ticket.product;
    },
    ticketType() {
      return parseInt(this.ticket.type, 10);
    },
    isSystem() {
      return this.ticketType && this.ticketType === 2;
    },
    isCombo() {
      return this.ticketType && this.ticketType === 1;
    },
    isSingle() {
      return this.ticketType && this.ticketType === 3;
    },
    bonus() {
      if (this.isSportsbookSM) {
        return this.ticket.ticketAppliedBonuses?.[0]?.amountMax?.toFixed(2) || 0.00;
      }

      return this.ticket.ticketBonus?.amount;
    },
    bonusPercentage() {
      if (this.isSportsbookSM) {
        return this.ticket.ticketAppliedBonuses?.[0]?.maxPercentage || 0;
      }

      return this.ticket.ticketBonus?.percentage;
    },
    combinations() {
      if (!this.isSystem) return null;

      if (this.isSportsbookSM) return this.ticket.numberOfCombinations || 0;

      return this.ticket.ticketCombinationGroups?.[0]?.numberOfCombinations || 0;
    },
    payinTax() {
      return this.ticket.payinTax || 0.00;
    },
    payment() {
      return this.isSportsbookSM ? this.ticket.paymentAmount : this.ticket.payin;
    },
    stake() {
      return this.isSportsbookSM ? this.ticket.stakeAmount : this.ticket.payin - this.payinTax;
    },
    minWinning() {
      if (!this.isSystem) return null;

      if (this.isSportsbookSM) return this.ticket.winningAmountMin || 0.00;

      return this.ticket.ticketCombinationGroups?.[0]?.minGain?.toFixed(2);
    },
    maxWinning() {
      return this.ticket.maxPossibleWin || 0.00;
    },
    maxWinningLabel() {
      return this.isSystem || this.isSingle ? config.maxPossibleWinLabel : config.possibleWinningLabel;
    },
    totalOdds() {
      if (!this.isCombo) return null;

      return this.ticket.totalOdd || false;
    },
    ticketUnitBets() {
      return this.ticket.ticketUnits?.[0].ticketUnitBets || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bets-item.list {
    height: 32px;
  }

  .preview-body {
    background-color: #242424;
    height: 100%;
    padding-top: 50px;
  }

  .preview-content {
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
  }

  .preview-content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bets {
    color: #fdfdfd;
    height: 100%;
    font-size: 14px;
  }

  .bets-items {
    height: 100%;
    padding: 1px;
    overflow: auto;
  }

  .bets-item {
    float: left;
    padding: 1px;

    &.list {
      width: 100%;
      display: flex;

      & > div {
        width: 100%;
      }
    }
  }

  .bet {
    color: #161616;
    background-color: #f5f5f5;
    height: 100%;
    border-radius: 3px;
  }

  &.horizontal {
    .bets {
      padding-bottom: 140px;
    }
  }
}

</style>
