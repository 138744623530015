<template>
  <div class="preview-header"
       :class="[color]">
    <div class="preview-header-title">{{ title }}</div>
    <div class="preview-header-customer"
      v-if="displayCustomerCardData"
      :class="customerCardColor">
        <i class="n-i n-i-user-name preview-header-customer-icon"></i>
        <span>{{ customerCardLabel }}</span>
    </div>
    <div class="preview-header-brand">
      {{ brand }}
    </div>
    <img v-if="logo"
         :src="logo"
         class="preview-header-logo" />
  </div>
</template>

<script>
import config from '../../config';
import eventBus from '../utility/eventBus';

export default {
  name: 'PreviewHeader',
  props: {
    title: {
      type: String,
      default: 'Betslip',
    },
  },
  computed: {
    customerFirstName() {
      const { firstName = '' } = this.customerCard;

      return firstName;
    },
    customerCardLabel() {
      return this.customerFirstName || config.customerCardNotScannedLabel;
    },
    customerCardColor() {
      return {
        'card-activated': this.customerFirstName,
        'card-deactivated': !this.customerFirstName,
      };
    },
  },
  methods: {
    setCustomerCard(data) {
      this.customerCard = data?.card || {};
    },
    setListeners() {
      eventBus.$on('CustomerCard', this.setCustomerCard);
    },
  },
  mounted() {
    this.setListeners();
  },
  data() {
    return {
      brand: config.brand,
      logo: config.logo,
      color: config.color,
      displayCustomerCardData: config.displayCustomerCardData,
      customerCard: {},
    };
  },
};
</script>

<style lang="scss">
.ticket-preview {
  .preview-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    padding: 0.5em 0.75em;
    background-color: black;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
    display: flex;
    align-items: center;
  }

  .preview-header-title {
    float: left;
  }

  .preview-header-brand {
    float: right;
  }

  .preview-header-logo {
    height: 35px;
    width: 85px;
    float: right;
    margin-right: 0.25em;
    font-size: 28px;
    vertical-align: middle;
  }

  .preview-header-customer {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #FDFDFD;
    margin-left: 24px;
  }

  .preview-header-customer-icon{
    margin-right: 8px;
    font-size: 1.2em;
  }

  .card-activated{
    background-color: #025730;
  }

  .card-deactivated{
    background-color: #C62323
  }

  .preview-header-title:empty + .preview-header-customer {
    margin-left: 0;
  }
}
</style>
