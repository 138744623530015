<template>
  <div class="preview-body">
    <div class="preview-placeholder">
      <img v-if="logo"
           :src="logo"
           class="preview-placeholder-logo" />
      <span class="preview-placeholder-text">
        {{ placeholder }}
      </span>
    </div>
    <div class="preview-content">
      <div class="preview-content-wrap"
           v-if="ticket.config">
        <PreviewBets :bets="ticket.bets"
                     :render-type="renderType"
                     :is-system="isSystem" />
        <PreviewFooter :bonus="bonus"
                       :bonus-percentage="bonusPercentage"
                       :combinations="combinations"
                       :payment="payment"
                       :stake="stake"
                       :min-winning="minWinning"
                       :max-winning="maxWinning"
                       :max-winning-label="maxWinningLabel"
                       :payin-tax="payinTax"
                       :total-odds="totalOdds" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewBets from './PreviewBets';
import PreviewFooter from './PreviewFooter';
import config from '../../config';

export default {
  name: 'PreviewBody',
  components: {
    PreviewBets,
    PreviewFooter,
  },
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
    renderType: {
      type: String,
    },
  },
  data() {
    return {
      logo: config.logo,
      placeholder: config.placeholder,
    };
  },
  computed: {
    isSystem() {
      return this.ticket.id && this.ticket.id === 'system';
    },
    isCombo() {
      return this.ticket.id && this.ticket.id === 'combo';
    },
    isSingle() {
      return this.ticket.id && this.ticket.id === 'single';
    },
    bonus() {
      const hasBonus = this.ticket.config.bonusId;
      if (!hasBonus) return null;

      return this.ticket.winnings ? this.ticket.winnings.bonusTotal : 0.00;
    },
    bonusPercentage() {
      const hasBonus = this.ticket.config.bonusId;
      if (!hasBonus) return null;

      return this.ticket.winnings ? this.ticket.winnings.bonusPercentage : 0.00;
    },
    combinations() {
      if (!this.isSystem) return null;
      const combinations = (this.ticket.config.group.id === 'SportsbookSM')
        ? this.ticket.numberOfCombinations : this.ticket.systems.totalCombinations;
      return this.ticket.systems ? combinations : 0;
    },
    payinTax() {
      const payinTaxesConfig = this.ticket.config?.group?.taxesConfig
        && this.ticket.config.group?.taxesConfig?.payin;
      const isPayinTaxEnabled = payinTaxesConfig && payinTaxesConfig.value;
      if (!isPayinTaxEnabled) return null;

      const taxesDefined = this.ticket.taxes && this.ticket.taxes.payin;
      return taxesDefined ? this.ticket.taxes.payin.taxAmountRounded : 0.00;
    },
    payment() {
      return this.ticket.stake;
    },
    stake() {
      if (this.payinTax === null) return null;

      return this.ticket.stake - this.payinTax;
    },
    minWinning() {
      if (!this.isSystem) return null;
      return this.ticket.winnings && this.ticket.winnings.min ? this.ticket.winnings.min : 0.00;
    },
    maxWinning() {
      const bonus = ((this.ticket.config.group?.id === 'SportsbookSM') ? 0 : this.bonus) || 0;
      return this.ticket.winnings ? this.ticket.winnings.max + bonus : 0.00;
    },
    maxWinningLabel() {
      return this.isSystem || this.isSingle ? config.maxPossibleWinLabel : config.possibleWinningLabel;
    },
    totalOdds() {
      if (!this.isCombo) return null;
      /* eslint-disable no-underscore-dangle */
      return this.ticket._oddsSum ? this.ticket._oddsSum : 0.00;
    },
  },
};
</script>

<style lang="scss">
.ticket-preview {
  &.sidebar .preview-placeholder {
    right: 25%;
  }

  .preview-body {
    background-color: #242424;
    height: 100%;
    padding-top: 50px;
  }

  .preview-content {
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
  }

  .preview-content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .preview-placeholder {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    color: #fff;
    font-size: 76px;
    line-height: 76px;
    transform: translate(0, -50%);
    opacity: 0.1;
    text-align: center;
  }

  .preview-placeholder-logo {
    margin-right: 0.15em;
    margin-top: -0.15em;
    font-size: 76px;
    height: 35px;
    width: 85px;
    vertical-align: middle;
  }
}
</style>
