<template>
  <TicketPreviewBetSportLayout
    :bet="bet"
    :is-system="isSystem"
    :color="color">
    <div class="wrapper"
         slot-scope="{
            betName,betsLayout,isBanker,isLocked,matchTime, matchTimeShort,matchDayAndTimeShort,oddChange,oddChangeColor,oddChangeIcon
          }">
      <component
        :is="renderBetsComponent"
        :bet="bet"
        :betName="betName"
        :betsLayout="betsLayout"
        :color="color"
        :isBanker="isBanker"
        :isLocked="isLocked"
        :isSystem="isSystem"
        :matchTime="matchTime"
        :matchTimeShort="matchTimeShort"
        :matchDayAndTimeShort="matchDayAndTimeShort"
        :oddChange="oddChange"
        :oddChangeColor="oddChangeColor"
        :oddChangeIcon="oddChangeIcon">
      </component>
    </div>
  </TicketPreviewBetSportLayout>
</template>

<script>
import { startCase } from 'lodash';
import config from '../../../config';
import TicketPreviewBetSportCards from './TicketPreviewBetSportCards';
import TicketPreviewBetSportList from './TicketPreviewBetSportList';
import TicketPreviewBetSportLayout from './TicketPreviewBetSportLayout';

export default {
  name: 'PreviewBetSport',
  components: {
    TicketPreviewBetSportCards,
    TicketPreviewBetSportList,
    TicketPreviewBetSportLayout,
  },
  props: {
    bet: {
      type: Object,
    },
    color: {
      type: String,
    },
    isSystem: {
      type: Boolean,
    },
  },
  data() {
    return {
      betsLayout: config.betsLayout,
    };
  },
  computed: {
    renderBetsComponent() {
      return `TicketPreviewBetSport${startCase(this.betsLayout)}`;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    height: 100%;
  }
</style>
