<template>
  <div class="marketing"
       :class="{ 'visible': isVisible }">
    <div class="marketing-overlay"></div>
    <iframe :src="url"
            frameborder="0"
            scrolling="no">
    </iframe>
  </div>
</template>

<script>
import config from '../../config';

export default {
  name: 'PreviewMarketing',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: config.marketingUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .marketing {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: all 0.8s linear;
    z-index: 3;

    .marketing-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.visible {
      transform: translateX(0);
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
</style>
